
<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item  active>{{$t('label_recommended')}}</b-breadcrumb-item>
            </div>
        </div>


        <b-card
                no-body
                class="mb-0"
        >

            <div class="m-2">

                <b-row>

                    <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                        />
                        <column-chooser v-model="selectedColumns" :not-touch="['id']"  :columns="columnsDefs"></column-chooser>

                    </b-col>

                    <!-- Search -->
                    <b-col
                            cols="12"
                            md="6"
                    >
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                    v-model="tableData.searchQuery"
                                    type="search"
                                    debounce="500"
                                    class="d-inline-block mr-1"
                                    :placeholder="$t('label_search')+'...'"
                            />
                            <b-button
                                    v-if="$can('edit', MODULE_PREFIX+'.tab_details')"
                                    :to="{name: MODULE_PREFIX + 's_add'}"
                                    variant="primary"
                            >
                                <span class="text-nowrap">{{ $t('label_add_' + MODULE_PREFIX) }}</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <!--sticky-header-->
            <div class="px-2 table-container-wrap">
                <b-table
                        ref="table"
                        class="position-relative transited-table"
                        :busy="isBusy"
                        :items="tableItems"
                        responsive
                        :fields="selectedColumns"
                        primary-key="id"
                        no-border-collapse
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #cell(status)="data">
                        <span>{{data.item.status == 'B'? $t('label_status_blocked') : $t('label_active')}}</span>
                    </template>
                    <template #cell(name)="data">

                        <router-link v-if="$can('view', MODULE_PREFIX+'.tab_details')" :to="{ name: MODULE_PREFIX + '-details', params: { id: data.item.id } }">
                            {{data.item.name }}
                        </router-link>

                    </template>
                    <template #cell(department)="data">

                        {{data.item.department? data.item.department.name : ''}}

                    </template>
                    <template #cell(manager)="data">

                        {{data.item.manager? data.item.manager.name : ''}}

                    </template>
                    <template #cell(lastlogintime)="data">

                        {{data.item.last_login && data.item.last_login.lastlogintime? formatDate(data.item.last_login.lastlogintime, 'DD.MM.YYYY', 'unix') : '--'}}

                    </template>
                    <template #cell(agreement_date)="data">

                        {{data.item.other_details && data.item.other_details.agreement_date? formatDate(data.item.other_details.agreement_date, 'DD.MM.YYYY', 'unix') : '--'}}

                    </template>


                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>


                            <b-dropdown-item @click="blockUnblockUser(data.item)">
                                <feather-icon :icon="data.item.status == 'B'? 'UnlockIcon' : 'LockIcon'"/>
                                <span class="align-middle ml-50">{{ $t(data.item.status == 'B'? 'action_unblock_user' : 'action_block_user') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item :to="{name: MODULE_PREFIX + 's_edit', params: { id: data.item.id }}">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                    @click="deleteItem(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>

                        </b-dropdown>
                    </template>

                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>

            </div>


        </b-card>

    </div>
</template>

<script>
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import columnChooser from '@/views/components/columnChooser'

    import {MODULE_PREFIX, USER_PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BCardHeader,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            vSelect,
            columnChooser
        },
        data() {
            return {

                USER_PREFIX,
                MODULE_PREFIX,

                editedItem: false,
                isSidebarOpen: false,

                selectedColumns:[],

                columnsDefs: [
                    // {label: '№', key: 'id', sortable: true, thClass: 'hidden', tdClass: 'hidden'},
                    {label: 'label_name', key: 'name', sortable: true},
                    {label: 'label_department', key: 'department', sortable: true},
                    {label: 'label_email', key: 'emailAddress', sortable: true},
                    {label: 'label_phone', key: 'phone', sortable: true},
                    {label: 'label_agreement_date', key: 'agreement_date', sortable: true},
                    {label: 'label_manager', key: 'manager', sortable: true},
                    {label: 'label_last_login', key: 'lastlogintime', sortable: true},
                    {label: 'label_status', key: 'status'},
                    {label: 'label_action', key: 'actions'}
                ],

                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },

                filterData: {
                    // paymentFromAt:null,
                },

                departments: []

            }
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }

        },

        created() {

            let self = this;
            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if (obj.key == 'actions' && !self.$can('edit', self.MODULE_PREFIX+'.tab_details')) {
                    return false;
                }
                return true;

            });

            this.refreshDataTable();

        },
        methods: {


            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/'+this.MODULE_PREFIX, {params: {
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        // created_at_from: this.filterData.dateFromFilter,


                    }}, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;

                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/'+this.MODULE_PREFIX+'/'+id, {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });
                });
            },
            blockUnblockUser(user){
                let action = ''; let title = ''; let text = '';

                if(user.status == 'B'){
                    action = 'unblock';
                    title='label_unblocking';
                    text='label_unblocking_text';
                } else {
                    action = 'block',
                        title = 'label_blocking';
                    text = 'label_blocking_text';
                }
                this.confirmAction(this.$t(title), this.$t(text),'warning',(result) => {
                    this.async('get', '/'+this.USER_PREFIX+'/'+action+'/' + user.id, {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });
                });
            }
        },


    }
</script>




<style scoped>

</style>
